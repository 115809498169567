import { Injectable, inject } from '@angular/core';
import {
  AssetFunctionTypeClient,
  CreateAssetFunctionTypeCommand,
  EditAssetFunctionTypeCommand
} from '../../clients/apiClients';

@Injectable({ providedIn: 'root' })
export class AssetFunctionTypeService {
  private client = inject(AssetFunctionTypeClient);

  list() {
    return this.client.list();
  }

  options() {
    return this.client.getOptions();
  }

  create(contract: CreateAssetFunctionTypeCommand) {
    return this.client.create(contract);
  }

  get(id: number) {
    return this.client.get(id);
  }

  getAssetsUsingType(id: number) {
    return this.client.getAssetsUsingType(id);
  }

  edit(contract: EditAssetFunctionTypeCommand) {
    return this.client.edit(contract);
  }

  delete(id: number) {
    return this.client.delete(id);
  }
}
